import { createRouter, createWebHistory, RouterView } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { resetPinia } from "@/services/resetPinia";


const PlanningView = () => import("../views/planning/PlanningView.vue");
const SettingsEditView = () => import("../views/settings/SettingsEditView.vue");
const CompaniesListView = () => import("@/views/companies/companiesListView.vue");
const CompanyDetailView = () => import("@/views/companies/companyDetailView.vue");
const LoginView = () => import("@/views/auth/LoginView.vue");
const BranchDetailView = () => import("@/views/companies/BranchDetailView/BranchDetailView.vue");
const ContractListView = () => import("@/views/contracts/ContractListView.vue");
const ContractDetailView = () => import("@/views/contracts/ContractDetailView.vue");
const HRMListView = () => import("@/views/hrm/HRMListView.vue");
const HRMDetailView = () => import("@/views/hrm/HRMDetailView.vue");
const ServiceListView = () => import("@/views/services/ServiceListView.vue");
const ServiceDetailView = () => import("@/views/services/ServiceDetailView.vue");
const RegisterView = () => import("@/views/auth/RegisterView.vue");
const PasswordResetView = () => import("@/views/auth/PasswordResetView.vue");
const NewPasswordView = () => import("@/views/auth/NewPasswordView.vue");
const WelcomeView = () => import("@/views/auth/WelcomeView.vue");
const StyleView = () => import ("@/views/StyleView/StyleView.vue");
const ContractRuleEditView = () => import("@/views/contracts/ContractRuleEditView/ContractRuleEditView.vue");

const ResendVerificationView = () => import("@/views/auth/ResendVerificationView.vue");

const VerifyCheckView = () => import("@/views/auth/VerifyCheckView.vue");

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      redirect: { name: "planning" }
    },
    {
      path: "/auth/email-verified",
      name: "email-verified",
      component: LoginView,
      props: { message: "Jouw e-mailadres is bevestigd. Je kunt nu inloggen." }
    },
    { path: "/public/styles", component: StyleView },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      props: true
    },
    {
      path: "/auth/register",
      component: RegisterView
    },
    {
      path: "/auth/resendVerification/:id",
      component: ResendVerificationView,
      name: "resendVerification",
      props: true
    },
    {
      path: "/auth/verify/:expires/:id",
      component: VerifyCheckView,
      props: true
    },
    {
      path: "/auth/passwordReset",
      component: PasswordResetView
    },
    {
      path: "/auth/welcome",
      component: WelcomeView
    },
    {
      path: "/auth/newPassword",
      component: NewPasswordView,
      props: true
    },
    {
      path: "/planning",
      name: "planning",
      component: PlanningView
    },
    { path: "/contracts", name: "contracts", redirect: { name: "planning" } },
    {
      path: "/companies",
      name: "companies",
      redirect: { name: "companiesList" },
      children: [
        {
          path: "",
          name: "companyList",
          component: CompaniesListView
        },
        {
          path: "create",
          name: "companyCreate",
          component: CompanyDetailView,
          props: true
        },
        {
          path: ":id",
          component: RouterView,
          children: [
            {
              path: "",
              name: "companyEdit",
              component: CompanyDetailView,
              props: true
            },
            {
              path: "branch/:branchId",
              name: "branchEdit",
              component: BranchDetailView,
              props: true
            },
            {
              path: "branch/create",
              name: "branchCreate",
              component: BranchDetailView,
              props: true
            }
          ]
        }
      ]
    },
    {
      path: "/contracts",
      name: "contracts",
      redirect: { name: "contractList" },
      children: [
        {
          path: "",
          name: "contractList",
          component: ContractListView
        },
        {
          path: "create",
          name: "contractCreate",
          component: ContractDetailView,
          props: true
        },
        {
          path: ":id",
          component: RouterView,
          children: [
            {
              path: "",
              name: "contractEdit",
              component: ContractDetailView,
              props: true
            },
            {
              path: "rule/:contractRuleId",
              name: "contractRuleEdit",
              component: ContractRuleEditView,
              props: true
            },
            {
              path: "rule/create",
              name: "contractRuleCreate",
              component: ContractRuleEditView,
              props: true
            }
          ]
        }
      ]
    },
    {
      path: "/hrm",
      name: "hrm",
      redirect: { name: "hrmList" },
      children: [
        {
          path: "",
          name: "hrmList",
          component: HRMListView
        },
        {
          path: "/hrm/:id",
          name: "hrmEdit",
          component: HRMDetailView,
          props: true
        },
        {
          path: "/hrm/create",
          name: "hrmCreate",
          component: HRMDetailView,
          props: true
        }
      ]
    },
    {
      path: "/services",
      name: "services",
      redirect: { name: "serviceList" },
      children: [
        {
          path: "",
          name: "serviceList",
          component: ServiceListView
        },
        {
          path: "/services/:id",
          name: "serviceEdit",
          component: ServiceDetailView,
          props: true
        },
        {
          path: "/services/create",
          name: "serviceCreate",
          component: ServiceDetailView,
          props: true
        }
      ]
    },
    {
      path: "/settings",
      name: "settings",
      component: SettingsEditView
    }
  ]
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();
  if (
    // make sure the user is authenticated
    !userStore.isAuthenticated() &&
    // ❗️ Avoid an infinite redirect
    !to.path.startsWith("/auth") && to.name !== "login" && !to.path.startsWith("/public")
  ) {
    resetPinia().all();
    return { name: "login" };
  }
});

export default router;
