import { ref } from "vue";
import type { Ref } from "vue";
import { defineStore } from "pinia";
import type { BreadCrumb } from "@/types/Types";

export const useMainStore = defineStore("main", () => {
  const loading = ref(false);
  const title = ref("");
  const search = ref("");
  const confirmed = ref(false);
  const breadcrumbs: Ref<BreadCrumb[]> = ref([]);

  function setLoading(value: boolean): void {
    loading.value = value;
  }

  function setConfirmed(): void {
    confirmed.value = true;
    setTimeout(() => confirmed.value = false, 1000)
  }

  function setCrumbs(crumbs: BreadCrumb[]): void {
    breadcrumbs.value = crumbs;
  }

  return { loading, title, search, breadcrumbs, setCrumbs, setLoading, confirmed, setConfirmed };
});
