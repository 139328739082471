import { ref } from "vue";
import type { Ref } from "vue";
import { defineStore } from "pinia";
import type { User } from "@/types/Types";
import { ApiConnector } from "@/services/ApiConnector";
import router from "@/router/router";
import { resetPinia } from "@/services/resetPinia";

export const useUserStore = defineStore("user", () => {
    const user: Ref<User> = ref({} as User);
    const url = "/api/profile/";
    const apiService: ApiConnector = new ApiConnector({}, url);

    async function login(email: string, password: string): Promise<void> {
      await getCookie();
      const result = await apiService.fetchData("/api/fortify/login", {
        method: "POST",
        body: JSON.stringify({ "email": email, "password": password })
      });
      if (!result) {
        throw Error("Login failed. Check your credentials.");
      }
      resetPinia().all();
      await getUser();
    }

    async function getCookie() {
      await apiService.fetchData("/sanctum/csrf-cookie");
    }

    function isAuthenticated(): boolean {
      if (localStorage.getItem("user")) {
        user.value = JSON.parse(localStorage.getItem("user") ?? "{}");
      }
      return user.value.email !== undefined;
    }

    async function getUser(): Promise<void> {
      user.value = {} as User;
      const data: User = await apiService.fetchData("/api/auth/user");
      if (data) {
        data.tenantName = data.tenant.name;
        localStorage.setItem("user", JSON.stringify(data));
        user.value = data;
      }
    }

    async function passwordReset(data: {}): Promise<void> {
      try {
        return await apiService.fetchData("/api/fortify/forgot-password", {
          method: "POST",
          body: JSON.stringify(data)
        });
      } catch (error: any) {
        throw Error("Wachtwoord reset is helaas niet gelukt.\n" + error.message);
      }
    }

    async function verifyAccount(url: string): Promise<Response> {
      try {
        return await fetch(url);
      } catch (error: any) {
        throw Error("We hebben het account helaas niet kunnen activeren.\n");
      }
    }

    async function resendVerification(id: string) {
      try {
        return await apiService.fetchData(`/api/auth/send-activation-notification/${id}`, {
            method: "GET"
          }
        )
          ;
      } catch
        (error: any) {
        throw Error("Het versturen van de verificatie-link is niet gelukt.\n" + error.message);
      }
    }

    async function setPassword(data: { username: string, token: string, "new-password": string, "password-confirm": string }): Promise<void> {
      try {
        return await apiService.fetchData("/api/fortify/reset-password", {
          method: "POST",
          body: JSON.stringify({
            email: data["username"],
            token: data["token"],
            password: data["new-password"],
            password_confirmation: data["new-password"]
          })
        });
      } catch (error: any) {
        throw Error("Wachtwoord opslaan is helaas niet gelukt.\n" + error.message);
      }
    }

    async function setPasswordAfterInvite(data: {
      username: string,
      token: string,
      "new-password": string,
      "password-confirm": string
    }): Promise<void> {
      try {
        return await apiService.fetchData("/api/auth/invite-set-password", {
          method: "POST",
          body: JSON.stringify({
            email: data["username"],
            token: data["token"],
            password: data["new-password"],
            password_confirmation: data["new-password"]
          })
        });
      } catch (error: any) {
        throw Error("Wachtwoord opslaan is helaas niet gelukt.\n" + error.message);
      }
    }

    async function register(data: {}): Promise<void> {
      try {
        return await apiService.fetchData("/api/fortify/register", {
          method: "POST",
          body: JSON.stringify(data)
        });
      } catch (error: any) {
        throw Error("Registratie is helaas niet gelukt. " + error.message);
      }
    }

    async function updateUser(data: User): Promise<void> {
      await apiService.update(data);
      await getUser();
    }

    function resetUser(): void {
      user.value = {} as User;
    }

    async function logout(): Promise<void> {
      await apiService.fetchData("/api/fortify/logout",{"method":"POST"});
      localStorage.removeItem("user");
      resetPinia().all();
      await router.push({ name: "login" });
    }

    return {
      user,
      passwordReset,
      getCookie,
      setPassword,
      verifyAccount,
      setPasswordAfterInvite,
      resendVerification,
      getUser,
      login,
      logout,
      resetUser,
      updateUser,
      isAuthenticated,
      register
    };
  })
;
