import { nl } from "@formkit/i18n";
import { generateClasses } from "@formkit/themes";
import myTailwindTheme from "./tailwind-theme";
import { genesisIcons } from "@formkit/icons";
import type { DefaultConfigOptions } from "@formkit/vue";
import { createProPlugin, datepicker, dropdown, autocomplete } from "@formkit/pro";


const proPlugin = createProPlugin("fk-31c1692683", {
  datepicker,
  dropdown,
  autocomplete
});

const config: DefaultConfigOptions = {
  icons: {
    ...genesisIcons
  },
  locales: { nl },
  locale: "nl",
  plugins: [proPlugin as any],
  config: { classes: generateClasses(myTailwindTheme) }
};

export default config;
