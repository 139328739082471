import { useMainStore } from "@/stores/main";
import type { ListQuery, Paginated } from "@/types/Types";
import type { Ref } from "vue";
import { resetPinia } from "@/services/resetPinia";
import router from "@/router/router";
import isEqual from "lodash.isequal";
import { Exception } from "@/execptions/Exception";
import { UnprocessableException } from "@/execptions/UnprocessableException";
import { NotFoundException } from "@/execptions/NotFoundException";
import { ServerErrorException } from "@/execptions/ServerErrorException";
import { useToast } from "vue-toastification";

export class ApiConnector {
  baseUrl = import.meta.env.VITE_ROOT_API || "https://api.rittr.app";
  baseInit: RequestInit = {
    headers: {
      accept: "application/json",
      "content-type": "application/json"
    },
    credentials: "include"
  };
  filter: Ref<ListQuery> = {} as Ref<ListQuery>;
  list: Ref<Paginated<any>> = {} as Ref<Paginated<any>>;
  url: string = "";

  constructor(list?: any, url: string = "") {
    this.list = list;
    this.url = url;
  }

  async fetchData(url: string, init?: RequestInit): Promise<any> {
    const mainStore = useMainStore();
    try {
      mainStore.loading = true;
      const response = await fetch(this.baseUrl + url, { ...this.baseInit, ...init });
      if (response.ok && response.status !== 204) {
        return await response.json();
      }
      if (response.status === 401 && !router.currentRoute.value.path.startsWith("/auth") && !router.currentRoute.value.path.startsWith("/public")) {
        const toast = useToast();
        toast.error("Je bent niet meer ingelogd. Log a.u.b. opnieuw in.");
        localStorage.removeItem("user");
        resetPinia().all();
        await router.push({ name: "login" });
        return;
      }
      if (response.status === 422) {
        const body = await response.json();
        throw new UnprocessableException(body.message, body.errors);
      }
      if (response.status === 404) {
        throw new NotFoundException();
      }
      if (response.status === 500) {
        throw new ServerErrorException();
      }

      if (!response.ok) {
        throw new Exception("Not 2xx response: " + response.status);
      }
      return response;
    } finally {
      mainStore.loading = false;
    }
  }

  async get(url: string): Promise<any> {
    return await this.fetchData(url);
  }

  async getList(force?: boolean, query?: ListQuery): Promise<Paginated<any>> {
    if (!this.list.value.data || !isEqual(this.filter.value, query) || force) {
      this.filter.value = query ?? {} as ListQuery;
      this.list.value = await this.fetchData(this.url + this.createUrlQuery(query));
    }
    return this.list.value;
  }

  async getSingle(id: string): Promise<any> {
    const index = this.findById(id, this.list.value.data);
    if (index > -1) return this.list.value.data[index];
    return await this.fetchData(this.url + "/" + id);
  }

  async update(record: any) {
    await this.fetchData(this.url + "/" + record.id, {
      method: "PUT",
      body: JSON.stringify(record)
    });
    if (this.list.value && this.list.value.data) {
      const index = this.findById(record.id, this.list.value.data);
      this.list.value.data[index] = record;
    }
  }

  async create(record: any): Promise<any> {
    const response = await this.fetchData(this.url, {
      method: "POST",
      body: JSON.stringify(record)
    });
    if (this.list.value.data) {
      this.list.value.data.push(response);
      this.list.value.total += 1;
    }
    return response;
  }

  /**
   * Do a post to the provided url
   *
   * @param record
   * @param url
   */
  async post(record: any, url: string): Promise<any> {
    const response = await this.fetchData(url, {
      method: "POST",
      body: JSON.stringify(record)
    });
    if (this.list.value.data) {
      this.list.value.data.push(response);
      this.list.value.total += 1;
    }
    return response;
  }

  async delete(record: any): Promise<void> {
    await this.fetchData(this.url + "/" + record.id, {
      method: "DELETE"
    });
    if (this.list && this.list.value.data) {
      const index = this.findById(record.id, this.list.value.data);
      if (index >= 0) {
        this.list.value.data.splice(index, 1);
      }
      this.list.value.total -= 1;
    }
  }

  findById(id: string, list: Array<any>): number {
    return list?.findIndex((e: any) => e.id === id);
  }

  createUrlQuery(query?: ListQuery): string {
    const queryArray = [];
    if (query) {
      queryArray.push(query.limit ? `limit=${query.limit}` : null);
      queryArray.push(query.perPage ? `perPage=${query.perPage}` : null);
      queryArray.push(query.page ? `page=${query.page}` : null);
      queryArray.push(query.sort ? `sort=${query.sort}` : null);
      for (const index in query.filter) {
        if (query.filter[index]) {
          queryArray.push(`filter[${index}]=${query.filter[index]}`);
        }
      }
    }
    const cleanArray = queryArray.filter(function(e) {
      return e;
    });
    return cleanArray.length > 0 ? "?" + cleanArray.join("&") : "";
  }

}