import "./assets/main.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import { plugin as formkitPlugin, defaultConfig } from "@formkit/vue";
import Toast from "vue-toastification";
import config from "./formkit.config";
import App from "./App.vue";
import router from "./router/router";
import cloneDeep from "lodash.clonedeep";

const app = createApp(App);

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/api\.rittr\.app\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || "production",
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const pinia = createPinia();
pinia.use(({ store }) => {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => {
    store.$patch($state => {
      Object.assign($state, initialState);
    });
  };
});

app.use(pinia);
app.use(router);
app.use(Toast);
app.use(formkitPlugin, defaultConfig(config));

app.mount("#app");
