import { Exception } from "@/execptions/Exception";

export class ServerErrorException extends Exception {
  constructor(data: any = null) {
    const message = "Er is een fout opgetreden. Probeer het later opnieuw.";
    for (const key in data) {
      data[key] = data[key].join("<br>");
    }
    super(message, 500, data);
  }
}